@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar-hidden {
    scrollbar-width: none !important;
    -ms-scrollbar-width: none !important;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    display: none !important;
  }

  .num-arrows-hidden {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  .num-arrows-hidden::-webkit-outer-spin-button,
  .num-arrows-hidden::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* custom scrollbar */
*::-webkit-scrollbar {
  @apply w-[10px];
}

*::-webkit-scrollbar-track {
  @apply bg-[#BABABA] dark:bg-[#151515] rounded-lg;
}

*::-webkit-scrollbar-thumb {
  @apply bg-[#6c757d] dark:bg-[#2D2D2D] rounded-lg;
}

a,
a:hover,
a:focus,
a:active,
a:visited,
a:link,
a:hover {
  text-decoration: none;
}

button {
  outline: none;
  border: none;
}

#filterDialog fieldset.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

/* MUI overrides specific to the filters dialog */
.MuiList-padding {
  padding: 0px !important;
}

.MuiMenu-paper {
  scrollbar-width: none !important;
  -ms-scrollbar-width: none !important;
  background-color: rgba(18, 18, 18, 0.7) !important;
}

#eventLogDialog .MuiDialog-paper {
  @apply !bg-transparent !rounded-xl;
}

#templateManagerDialog .MuiDialog-paper {
  @apply !bg-transparent !rounded-xl;
}

#templateManagerDialog .MuiButtonBase-root {
  border: 2px solid transparent !important;
}

#templateManagerDialog .MuiButtonBase-root.Mui-selected {
  @apply bg-[#C5C5C5] dark:bg-[#2c2c2c];
  border: 2px solid rgb(103, 0, 4) !important;
}

#templateManagerDialog .MuiInput-root {
  @apply !mt-0;
}

.MuiPaper-root::-webkit-scrollbar {
  display: none !important;
}

.MuiMenuItem-root {
  background-color: rgba(18, 18, 18, 0.7) !important;
  color: white !important;
}

.MuiMenuItem-root:hover {
  background-color: rgb(44, 44, 44) !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: rgb(69, 69, 69) !important;
}

.MuiMenuItemAlt-root:hover {
  background-color: rgb(44, 44, 44) !important;
}

.MuiMenuItemAlt-root.Mui-selected {
  background-color: rgb(103, 0, 4) !important;
}

#filterDialog .Mui-disabled {
  -webkit-text-fill-color: white !important;
}

#filterDialog * {
  user-select: none;
}
#eventLogDialog * {
  user-select: none;
}
#templateManagerDialog * {
  user-select: none;
}

#audioFiles {
  user-select: none;
}

.react-transform-wrapper,
.react-transform-component {
  width: 100% !important;
  height: 100% !important;
  border-radius: 16px;
}
