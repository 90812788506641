.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #1a1a1a;
  padding: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
  padding: 30px;
  background-color: #2d2d2d;
  border-radius: 15px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.login-form > * {
  margin-bottom: 15px;
}

.login-form > *:last-child {
  margin-bottom: 0;
}

.login-form input {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
  background-color: #3d3d3d;
  color: #ffffff;
  border: 1px solid #4a4a4a;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.login-form input:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.login-form button[type="submit"] {
  margin-top: 30px;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #4a90e2;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.login-form button[type="submit"]:hover {
  background-color: #357abd;
}

.or {
  color: #888888;
  font-size: 14px;
  margin: 25px 0;
  position: relative;
  width: 100%;
  text-align: center;
}

.or::before,
.or::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 42%;
  height: 1px;
  background-color: #4a4a4a;
}

.or::before {
  left: 0;
}

.or::after {
  right: 0;
}

.login-form button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background-color: #2d2d2d;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  border: 1px solid #4a4a4a;
}

.login-form button:hover {
  background-color: #3d3d3d;
}

.forgotString {
  font-size: 14px;
  color: #888888;
}

.forgotString span {
  font-size: 14px;
  color: #4a90e2;
  cursor: pointer;
}

.error-message {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgba(220, 53, 69, 0.2);
}

h1 {
  color: #ffffff;
  margin-bottom: 25px;
}

button.backToLogin {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #4a4a4a;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

button.backToLogin:hover {
  background-color: #5a5a5a;
}

/* Center the form and logo container on different screen sizes */
